'use client'

import { PropsWithChildren, createContext, useContext, useState } from 'react'
import { useStore } from 'zustand'

import {
  createSessionStore,
  sessionStoreDefaultState,
  setSessionInstanceStore,
  type SessionStateType,
  type UserSliceType,
  type SessionStoreType,
  getSessionStore,
} from './store'

export type SessionStoreProviderPropsType = PropsWithChildren<{
  initState: SessionStateType
}>

export const SessionStoreContext = createContext<SessionStoreType | null>(null)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let sessionStoreInstance: any = null

export const SessionStoreProvider = ({ children, initState }: SessionStoreProviderPropsType) => {
  const [store] = useState(() => {
    if (!sessionStoreInstance) {
      sessionStoreInstance = createSessionStore({ ...sessionStoreDefaultState, ...initState })
    }
    return sessionStoreInstance
  })

  const currentStore = getSessionStore()
  if (!currentStore) {
    setSessionInstanceStore(sessionStoreInstance)
  }

  return <SessionStoreContext.Provider value={store}>{children}</SessionStoreContext.Provider>
}

export const useSessionStoreContext = () => useContext(SessionStoreContext)

const useSessionStore = <T,>(selector: (store: UserSliceType) => T): T => {
  const sessionStoreContext = useSessionStoreContext()

  if (!sessionStoreContext) {
    throw new Error(`useSessionStore must be use within SessionStoreProvider`)
  }

  return useStore(sessionStoreContext, selector)
}

export default useSessionStore
