'use client'

import Link from 'next/link'
import { useTranslations } from 'next-intl'
import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { Button } from '@astronautsid/wpe-astro-ui/components/atoms/Button'
import { TextInput } from '@astronautsid/wpe-astro-ui/components/atoms/TextInput'
import { useTheme } from '@astronautsid/wpe-astro-ui/theme'

import { HEIGHT_INSTALL_BANNER } from 'components/Layout'
import useUserStore from 'store/user'

import useLogin from '../_hooks/useLogin'

type LoginContentType = {
  mainDescription?: string
}

const LoginContent = ({ mainDescription = '' }: LoginContentType) => {
  const theme = useTheme()
  const t = useTranslations('login')
  const { register, onSubmit, onGuestLogin, handleSubmit, formState, isPending, onAstoCareClick } =
    useLogin()

  const { isShowAstroAppBanner } = useUserStore((state) => state)

  const { errors } = formState

  const description = mainDescription || t('description')

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      sx={{
        minHeight: '100vh',
      }}
      style={{
        minHeight: '100svh',
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'stretch',
          flexDirection: 'column',
          pt: '84px',
          px: '16px',
          pb: '24px',
          gap: '24px',
          height: '100%',
        }}
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Typography
          data-testid="headerTextLogin"
          variant="display-huge"
          component="h1"
          color="white"
        >
          {description}
        </Typography>
        <TextInput
          data-testid="inputPhoneNumber"
          fullWidth
          type="tel"
          size="medium"
          placeholder={t('phoneNumber')}
          name="phoneNumber"
          error={!!errors?.phone?.message}
          helperText={!errors?.phone?.message ? '' : t(errors?.phone?.message)}
          FormHelperTextProps={{
            color: theme.palette.textColor.error,
          }}
          inputProps={{
            sx: { fontSize: '14px', lineHeight: '22px' },
            ...register('phone'),
          }}
          wrapperProps={{
            sx: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              '& .MuiInputBase-root': {
                background: 'white',
                height: '52px',
              },
            },
          }}
        />
        <Button
          data-testid="btnSubmitPhoneNumber"
          disabled={Object.keys(formState.errors).length > 0 || isPending}
          type="submit"
        >
          {t('buttonSubmit')}
        </Button>
        <Typography
          data-testid="linkToAstroCare"
          textAlign="center"
          color="white"
          variant="body-small"
        >
          {t('help')}
          <Link prefetch={false} href="/astrocare" onClick={onAstoCareClick}>
            <Typography
              color="white"
              variant="body-small"
              sx={{
                fontWeight: 800,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                ':hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              Astro Care
            </Typography>
          </Link>
        </Typography>
      </Box>
      <Button
        data-testid="btnGuestLogin"
        onClick={onGuestLogin}
        sx={{
          color: 'white',
          mt: 'auto',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          ':hover': {
            color: 'white',
          },
          mb: isShowAstroAppBanner ? `${HEIGHT_INSTALL_BANNER}px` : '32px',
        }}
        variant="naked"
        fullWidth
      >
        {t('guest')}
      </Button>
    </Box>
  )
}

export default LoginContent
