'use client'

import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import Layout from 'components/Layout/Layout'

export type LoginContainerPropsType = {
  children: React.ReactNode
}

const LoginContainer = ({ children }: LoginContainerPropsType) => (
  <Box
    sx={{
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#00A8FF',
      backgroundSize: 'cover',
      minHeight: '100vh',
    }}
    style={{
      minHeight: '100svh',
    }}
  >
    <Layout withInstallBanner noOffset>
      {children}
    </Layout>
  </Box>
)

export default LoginContainer
