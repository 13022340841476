import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import useRouter from 'hooks/useRouter'
import { useTranslations } from 'next-intl'
import { type AxiosError } from '@astronautsid/wpe-utils'

import { useMutation } from '@tanstack/react-query'

import {
  TrackEventsPropertiesType,
  getScreenNameMetaFromDocument,
  trackEvents,
} from 'lib/mixpanel/mixpanelTracker'
import { mixpanelEventName } from 'utils/tracking/mixpanel/mixpanelConstants'
import useUser, { REDIRECT_PATH_SEARCH_KEY } from 'hooks/useUser'

import { GenerateOtpRequestType, generateOtpSchema } from '../_utils/requests'
import { generateOtpService } from '../_utils/services'

const useLogin = () => {
  const { register, handleSubmit, formState } = useForm<GenerateOtpRequestType>({
    resolver: zodResolver(generateOtpSchema),
    mode: 'all',
  })

  const t = useTranslations('login')

  const { getLatestLoginRedirectPath } = useUser()

  const router = useRouter()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: generateOtpService,
    onSuccess: (resp, params) => {
      if (!resp.data.error.status) {
        toast.success(t('otpSent'))

        router.push(
          `/login/otp?phone=${
            params.phone
          }&${REDIRECT_PATH_SEARCH_KEY}=${getLatestLoginRedirectPath()}`,
        )
      }
    },
    onError: (error) => {
      const axiosError = error as AxiosError<{ error: { message: string } }>
      const errorAxios = axiosError.response?.data?.error.message || ``

      if (errorAxios === ``) {
        // eslint-disable-next-line no-console
        console.error(error.message)
        toast.error(t(error.message))
      } else {
        // eslint-disable-next-line no-console
        console.error(axiosError.response?.data)
        toast.error(axiosError?.response?.data?.error?.message || t('General Error'))
      }
    },
  })

  const handleTrackEventClick = (event: string, properties: TrackEventsPropertiesType) => {
    const screenName = getScreenNameMetaFromDocument()

    trackEvents(event, {
      screenName,
      eventCategory: mixpanelEventName.LOGIN,
      ...properties,
    })
  }

  const onSubmit = async (params: GenerateOtpRequestType) => {
    /**
     * @NOTE: this api is used to determine if user have PIN set or not,
     * i think we can skip this for this iteration?
     */
    // const customerResp = await getCustomer(params.phone)
    /* @TODO: should consider handle PIN login instead of OTP according to customerResp */
    // console.log(customerResp.data.data.login_using_pin)
    // await generateOtpService(params)
    //   .then((resp) => {
    //     if (!resp.data.error.status) {
    //       toast.success(t('otpSent'))
    //       router.push('/login/otp')
    //     }
    //   })
    //   .catch((err) => {
    //     if (err?.response) {
    //       // eslint-disable-next-line no-console
    //       console.error(err?.response?.data?.error)
    //       toast.error(t(err?.response?.data?.error?.message))
    //       return
    //     }
    //     // eslint-disable-next-line no-console
    //     console.error(err)
    //   })

    handleTrackEventClick(mixpanelEventName.LOGIN, {
      eventAction: 'click login',
      eventLabel: params.phone,
    })

    await mutateAsync(params)
  }

  const onGuestLogin = () => {
    handleTrackEventClick(mixpanelEventName.CLICK_COMPONENT, {
      eventAction: 'click masuk sebagai pengunjung',
      eventLabel: '',
    })

    router.push('/')
  }

  const onAstoCareClick = () => {
    handleTrackEventClick(mixpanelEventName.CLICK_COMPONENT, {
      eventAction: 'click astro care',
      eventLabel: '',
    })
  }

  return {
    register,
    handleSubmit,
    formState,
    onSubmit,
    onGuestLogin,
    onAstoCareClick,
    isPending,
  }
}

export default useLogin
