import { z } from 'zod'

export const generateOtpSchema = z.object({
  phone: z.coerce
    .number({
      errorMap: (issue, { defaultError }) => {
        if (issue.code === 'invalid_type') {
          return {
            message: 'Phone must be a number',
          }
        }
        return { message: defaultError }
      },
    })
    .transform((val) => `0${String(val)}`),
})

export type GenerateOtpRequestType = z.infer<typeof generateOtpSchema>

export const validateOtpSchema = z.object({
  phone: z.coerce
    .number({
      errorMap: (issue, { defaultError }) => {
        if (issue.code === 'invalid_type') {
          return {
            message: 'Phone must be a number',
          }
        }
        return { message: defaultError }
      },
    })
    .transform((val) => `0${String(val)}`),
  otp: z.string().min(6),
})

export type ValidateOtpRequestType = z.infer<typeof validateOtpSchema>

export type UpdateUserSessionRequestType<TUser, TAddress> = {
  token?: string
  sessionToken?: string
  user: TUser
  customerAddress?: TAddress
}
