import { type PropsWithChildren, type ReactNode } from 'react'
import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'

import TopNav, { type TopNavPropsType } from 'components/TopNav'
import dynamicImports from 'next/dynamic'

const BottomNavigation = dynamicImports(() => import('components/BottomNavigation'))
const CartWidget = dynamicImports(() => import('components/CartWidget'), { ssr: false })
const AppInstallBanner = dynamicImports(() => import('components/AppInstallBanner'), { ssr: false })
const BottomSheetConfirmationAge = dynamicImports(
  () =>
    import(
      'app/(categoryandcatalogue)/_components/BottomSheetConfirmationAge/BottomSheetConfirmationAge'
    ),
  { ssr: false },
)

type LayoutPropsType = PropsWithChildren & {
  withBottomNavBar?: boolean
  withTopNav?: boolean
  topNavProps?: TopNavPropsType
  withCartWidget?: boolean
  withInstallBanner?: boolean
  footer?: ReactNode
  noOffset?: boolean
}

export const HEIGHT_NAVBAR = 48
export const HEIGHT_INSTALL_BANNER = 52
export const BOTTOM_GAP_DEFAULT = 5

export const OFFSET_TOP = 56
export const OFFSET_TOP_PADDING = `${OFFSET_TOP}px`

const getOffsetBottom = (
  props: Pick<LayoutPropsType, 'withInstallBanner' | 'withBottomNavBar' | 'noOffset'>,
) => {
  if (props.noOffset) {
    return 0
  }

  let offset = BOTTOM_GAP_DEFAULT

  if (props.withInstallBanner) {
    offset += HEIGHT_INSTALL_BANNER
  }

  if (props.withBottomNavBar) {
    offset += HEIGHT_NAVBAR
  }

  return offset
}

const Layout = ({
  children,
  withBottomNavBar = false,
  withTopNav = false,
  withCartWidget = false,
  topNavProps,
  withInstallBanner = false,
  footer,
  noOffset = false,
}: LayoutPropsType) => {
  const offsetBottom = getOffsetBottom({ withBottomNavBar, withInstallBanner, noOffset })

  const offsetBottomStyle = `${offsetBottom}px`

  return (
    <>
      <Box
        component="main"
        minHeight="550px"
        position="relative"
        paddingBottom={!footer ? offsetBottomStyle : 'initial'}
        paddingTop={withTopNav ? OFFSET_TOP_PADDING : 'initial'}
      >
        {children}
        {withBottomNavBar && (
          <Box
            position="fixed"
            bottom={0}
            left="50%"
            // eslint-disable-next-line sonarjs/no-duplicate-string
            sx={{ transform: 'translateX(-50%)' }}
            width="100%"
            maxWidth="430px"
            zIndex={1}
          >
            <BottomNavigation />
          </Box>
        )}
        {withInstallBanner && <AppInstallBanner />}
        {withTopNav && (
          <Box
            position="fixed"
            top={0}
            left="50%"
            // eslint-disable-next-line sonarjs/no-duplicate-string
            sx={{ transform: 'translateX(-50%)' }}
            width="100%"
            maxWidth="430px"
            zIndex={1}
          >
            <TopNav {...topNavProps} />
          </Box>
        )}
        {withCartWidget && (
          <Box
            position="fixed"
            bottom={`calc(${offsetBottomStyle} + 24px)`}
            right="calc(50vw - 140px)"
            sx={{
              transform: 'translateX(50%)',
            }}
            zIndex={1}
          >
            <CartWidget />
          </Box>
        )}
      </Box>
      {footer && (
        <Box component="footer" paddingBottom={footer ? offsetBottomStyle : 'initial'}>
          {footer}
        </Box>
      )}
      <BottomSheetConfirmationAge />
    </>
  )
}

export default Layout
